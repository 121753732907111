import { FunctionComponent } from "react";
import styles from "./CreditCardTextFrame.module.css";
import Property1Default6 from "./Property1Default6"

const CreditCardTextFrame: FunctionComponent = () => {
  return (
    <section className={styles.creditCardTextFrame}>
      <div className={styles.creditCard}>Credit Card</div>
      <div className={styles.creditCardFrame}>
        <div className={styles.radioButton2}>
          <label className={styles.radioLabel}>
            <input
              type="radio"
              name="paymentMethod"
              value="card"
              className={styles.radioInput1}
            />
            <div className={styles.radiobutton1}>
              <div>
                
              </div>
            </div>
          </label>
          <img
            className={styles.paymentMethodvisaIcon}
            alt=""
            src="/payment-methodvisa@2x.png"
          />
          <img
            className={styles.paymentMethodamexIcon}
            alt=""
            src="/payment-methodamex@2x.png"
          />
          <img
            className={styles.paymentMethodmastercardIcon}
            alt=""
            src="/payment-methodmastercard@2x.png"
          />
          <img
            className={styles.paymentMethodjcbIcon}
            alt=""
            src="/payment-methodjcb.svg"
          />
        </div>
        <div className={styles.radioButton2}>
          <label className={styles.radioLabel}>
            <input
              type="radio"
              name="paymentMethod"
              value="paypal"
              className={styles.radioInput2}
            />
            <div className={styles.radiobutton3}>
              <div>

              </div>
            </div>
          </label>
          <img
              className={styles.paymentMethodpaypalIcon}
              loading="eager"
              alt=""
              src="/payment-methodpaypal.svg"
            />
        </div>
      </div>
      <div className={styles.cardNumberNameOnCardFrame}>
        <div className={styles.cardNumberTextFieldSmall1}>
          <div style={{fontSize:"var(--caption-cap-12-size)", lineHeight:"2.604166666666667vh"}}>
            Card Number
          </div>
          <div className={styles.textfieldsmall1}>
            <input
              className={styles.cardNumber}
              type="text"
              style={{width:"100%", padding:"0"}}
            />
          </div>
        </div>
        <div className={styles.cardNumberTextFieldSmall1}>
          <div style={{fontSize:"var(--caption-cap-12-size)", lineHeight:"2.604166666666667vh"}}>
            Name on Card
          </div>
          <div className={styles.textfieldsmall1}>
            <input
              className={styles.nameOnCard}
              type="text"
              style={{width:"100%", padding:"0"}}
            />
          </div>
        </div>
        <div className={styles.cardNumberTextFieldSmall2}>
          <div className={styles.expiryParent}>
            <div className={styles.expiry}>Expiry</div>
            <div className={styles.nameOnCardFrame}>
              <Property1Default6
                property1DefaultWidth="6.527777777777779vw"
                property1DefaultJustifyContent="space-between"
                property1DefaultPadding="var(--padding-9xsH) var(--padding-xsH)"
                property1DefaultGap="unset"
                property1DefaultPosition="relative"
                property1DefaultTop="unset"
                property1DefaultLeft="unset"
                property1DefaultBackgroundColor="transparent"
                property1DefaultFontFamily="Onest"
                property1DefaultFontWeight="500"
                property1DefaultFontSize="1.8229166666666667vh"
                property1DefaultColor="var(--colors-purple-light)"
                property1DefaultAlignSelf="unset"
                options={["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]}
                value="Jan"
              />
              <Property1Default6
                property1DefaultWidth="6.527777777777779vw"
                property1DefaultJustifyContent="space-between"
                property1DefaultPadding="var(--padding-9xsH) var(--padding-xsW)"
                property1DefaultGap="unset"
                property1DefaultPosition="relative"
                property1DefaultTop="unset"
                property1DefaultLeft="unset"
                property1DefaultBackgroundColor="transparent"
                property1DefaultFontFamily="Onest"
                property1DefaultFontWeight="500"
                property1DefaultFontSize="1.8229166666666667vh"
                property1DefaultColor="var(--colors-purple-light)"
                property1DefaultAlignSelf="unset"
                options={["2024","2025"]}
                value="2024"
              />
            </div>
          </div>
          <div className={styles.securityCodeParent}>
            <div style={{fontSize:"var(--caption-cap-12-size)", lineHeight:"2.604166666666667vh"}}>
              Security Code
            </div>
            <div className={styles.cVVArea}>
              <div className={styles.textfieldsmall1}>
                <input
                  className={styles.securityCode}
                  type="text"
                  style={{width:"100%", padding:"0"}}
                />
              </div>
              <div className={styles.cvvArea}>
                <img className={styles.iconCvv} alt="" src="/icon--cvv.svg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CreditCardTextFrame;
