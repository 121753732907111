import { FunctionComponent, type CSSProperties } from "react";
import { Property } from "csstype";
import styled from "styled-components";

type StateHoverIconnoType = {
  saveSession?: string;
  isActive?: boolean;

  /** Style props */
  stateHoverIconnoJustifyContent?: CSSProperties["justifyContent"];
  stateHoverIconnoWidth?: CSSProperties["width"];
  stateHoverIconnoZIndex?: CSSProperties["zIndex"];
  stateHoverIconnoBackgroundColor?: CSSProperties["backgroundColor"];
  stateHoverIconnoCursor?: CSSProperties["cursor"];
  stateHoverIconnoBorderW?: CSSProperties["borderWidth"];
  stateHoverIconnoBorderS?: CSSProperties["borderStyle"];
  stateHoverIconnoBorderC?: CSSProperties["borderColor"];
  stateHoverIconnoHeight?: CSSProperties["height"];
  stateHoverIconnoPadding?: CSSProperties["padding"];
  stateHoverIconnoBoxSizing?: CSSProperties["boxSizing"];
  stateHoverIconnoPosition?: CSSProperties["position"];
  stateHoverIconnoTop?: CSSProperties["top"];
  stateHoverIconnoLeft?: CSSProperties["left"];
  saveSessionColor?: CSSProperties["color"];
  saveSessionDisplay?: CSSProperties["display"];

  /** Action props */
  onButtonSecondaryClick?: (e: React.MouseEvent<HTMLElement>) => void;
};

const SaveSession = styled.div<{
  saveSessionColor?: Property.Color;
  saveSessionDisplay?: Property.Display;
}>`position: relative;
  line-height: 2.604166666666667vh;
  font-weight: 500;
  color: ${(p) => p.saveSessionColor};
  display: ${(p) => p.saveSessionDisplay};
`;
const StatehoverIconnoRoot = styled.div<{
  stateHoverIconnoJustifyContent?: Property.JustifyContent;
  stateHoverIconnoWidth?: Property.Width;
  stateHoverIconnoZIndex?: Property.ZIndex;
  stateHoverIconnoBackgroundColor?: Property.BackgroundColor;
  stateHoverIconnoCursor?: Property.Cursor;
  stateHoverIconnoBorderW?: Property.BorderWidth;
  stateHoverIconnoBorderC?: Property.BorderColor;
  stateHoverIconnoBorderS?: Property.BorderStyle;
  stateHoverIconnoHeight?: Property.Height;
  stateHoverIconnoPadding?: Property.Padding;
  stateHoverIconnoBoxSizing?: Property.BoxSizing;
  stateHoverIconnoPosition?: Property.Position;
  stateHoverIconnoTop?: Property.Top;
  stateHoverIconnoLeft?: Property.Left;
  isActive: boolean;
}>`border-radius: var(--corner-radius-l);
  background-color: var(--colors-darkerblue);
  height: 4.166666666666666vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0.0vh var(--padding-xsW);
  box-sizing: border-box;
  text-align: left;
  font-size: 1.6927083333333333vh;
  color: var(--colors-darkerblue);
  font-family: var(--buttons-btn-input);
  justify-content: ${(p) => p.stateHoverIconnoJustifyContent};
  width: ${(p) => p.stateHoverIconnoWidth};
  z-index: ${(p) => p.stateHoverIconnoZIndex};
  background-color: ${(p) => p.stateHoverIconnoBackgroundColor};
  cursor: ${(p) => p.isActive?p.stateHoverIconnoCursor:"normal"};
  border-width: ${(p) => p.stateHoverIconnoBorderW};
  border-color: ${(p) => p.stateHoverIconnoBorderC};
  border-style: ${(p) => p.stateHoverIconnoBorderS};
  height: ${(p) => p.stateHoverIconnoHeight};
  padding: ${(p) => p.stateHoverIconnoPadding};
  box-sizing: ${(p) => p.stateHoverIconnoBoxSizing};
  position: ${(p) => p.stateHoverIconnoPosition};
  top: ${(p) => p.stateHoverIconnoTop};
  left: ${(p) => p.stateHoverIconnoLeft};
  opacity: ${(p) => p.isActive?"1":"0.4"};
  ${
    (p) => p.isActive?
    "&:hover {background-color: var(--colors-darkerblue);div {color: var(--colors-btn-txt);}}"
    :
    ""
  };
`;

const StateHoverIconno: FunctionComponent<StateHoverIconnoType> = ({
  saveSession,
  stateHoverIconnoJustifyContent,
  stateHoverIconnoWidth,
  stateHoverIconnoZIndex,
  stateHoverIconnoBackgroundColor,
  stateHoverIconnoCursor,
  stateHoverIconnoBorderW,
  stateHoverIconnoBorderS,
  stateHoverIconnoBorderC,
  stateHoverIconnoHeight,
  stateHoverIconnoPadding,
  stateHoverIconnoBoxSizing,
  stateHoverIconnoPosition,
  stateHoverIconnoTop,
  stateHoverIconnoLeft,
  saveSessionColor,
  saveSessionDisplay,
  onButtonSecondaryClick,
  isActive=true,
}) => {
  return (
    <StatehoverIconnoRoot
      stateHoverIconnoJustifyContent={stateHoverIconnoJustifyContent}
      stateHoverIconnoWidth={stateHoverIconnoWidth}
      stateHoverIconnoZIndex={stateHoverIconnoZIndex}
      stateHoverIconnoBackgroundColor={stateHoverIconnoBackgroundColor}
      stateHoverIconnoCursor={stateHoverIconnoCursor}
      stateHoverIconnoBorderW={stateHoverIconnoBorderW}
      stateHoverIconnoBorderC={stateHoverIconnoBorderC}
      stateHoverIconnoBorderS={stateHoverIconnoBorderS}
      stateHoverIconnoHeight={stateHoverIconnoHeight}
      stateHoverIconnoPadding={stateHoverIconnoPadding}
      stateHoverIconnoBoxSizing={stateHoverIconnoBoxSizing}
      stateHoverIconnoPosition={stateHoverIconnoPosition}
      stateHoverIconnoTop={stateHoverIconnoTop}
      stateHoverIconnoLeft={stateHoverIconnoLeft}
      onClick={(event)=>{if (isActive && onButtonSecondaryClick){onButtonSecondaryClick(event)}}}
      isActive={isActive}
    >
      <SaveSession
        saveSessionColor={saveSessionColor}
        saveSessionDisplay={saveSessionDisplay}
      >
        {saveSession}
      </SaveSession>
    </StatehoverIconnoRoot>
  );
};

export default StateHoverIconno;
