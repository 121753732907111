import { FunctionComponent, type CSSProperties } from "react";
import { Property } from "csstype";
import styled from "styled-components";

type StateDefaultNotificationnoType = {
  smallFlashCards?: string;
  text?: string;

  /** Style props */
  stateDefaultNotificationnCursor?: CSSProperties["cursor"];
  stateDefaultNotificationnBorderW?: CSSProperties["borderWidth"];
  stateDefaultNotificationnBorderS?: CSSProperties["borderStyle"];
  stateDefaultNotificationnBorderC?: CSSProperties["borderColor"];
  stateDefaultNotificationnBackgroundColor?: CSSProperties["backgroundColor"];
  stateDefaultNotificationnWidth?: CSSProperties["width"];
  stateDefaultNotificationnFlexShrink?: CSSProperties["flexShrink"];
  stateDefaultNotificationnBoxSizing?: CSSProperties["boxSizing"];
  stateDefaultNotificationnBorderBottom?: CSSProperties["borderBottom"];
  smallFlashCardsIconOverflow?: CSSProperties["overflow"];
  smallFlashCardsIconFlexShrink?: CSSProperties["flexShrink"];
  smallFlashCardsIconOpacity?: CSSProperties["opacity"];
  flashCardsDisplay?: CSSProperties["display"];
  flashCardsColor?: CSSProperties["color"];
  HasClass?: string;

  /** Action props */
  onTabPrimaryB3Click?: (e: React.MouseEvent<HTMLElement>) => void;
};

const SmallflashcardsIcon = styled.img<{
  smallFlashCardsIconOverflow?: Property.Overflow;
  smallFlashCardsIconFlexShrink?: Property.FlexShrink;
  smallFlashCardsIconOpacity?: Property.Opacity;
}>`position: relative;
  --side: min(1.1111111111111112vw,2.083333333333333vh);
  width: var(--side);
  height: var(--side);
  object-fit: cover;
  overflow: ${(p) => p.smallFlashCardsIconOverflow};
  flex-shrink: ${(p) => p.smallFlashCardsIconFlexShrink};
  opacity: ${(p) => p.smallFlashCardsIconOpacity};
`;
const FlashCards = styled.div<{
  flashCardsDisplay?: Property.Display;
  flashCardsColor?: Property.Color;
}>`position: relative;
  line-height: 2.604166666666667vh;
  font-weight: 500;
  display: ${(p) => p.flashCardsDisplay};
  color: ${(p) => p.flashCardsColor};
`;
const StatedefaultNotificationnoRoot = styled.div<{
  stateDefaultNotificationnCursor?: Property.Cursor;
  stateDefaultNotificationnBorderW?: Property.BorderWidth;
  stateDefaultNotificationnBorderC?: Property.BorderColor;
  stateDefaultNotificationnBorderS?: Property.BorderStyle;
  stateDefaultNotificationnBackgroundColor?: Property.BackgroundColor;
  stateDefaultNotificationnWidth?: Property.Width;
  stateDefaultNotificationnFlexShrink?: Property.FlexShrink;
  stateDefaultNotificationnBoxSizing?: Property.BoxSizing;
  stateDefaultNotificationnBorderBottom?: Property.BorderBottom;
}>`display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xsH) var(--padding-13xlW) var(--padding-baseH) var(--padding-xlW);
  gap: var(--gap-5xs);
  text-align: left;
  font-size: var(--body-main-size);
  color: var(--colors-dark-1);
  font-family: var(--buttons-btn-input);
  cursor: ${(p) => p.stateDefaultNotificationnCursor};
  border-width: ${(p) => p.stateDefaultNotificationnBorderW};
  border-color: ${(p) => p.stateDefaultNotificationnBorderC};
  border-style: ${(p) => p.stateDefaultNotificationnBorderS};
  background-color: ${(p) => p.stateDefaultNotificationnBackgroundColor};
  width: ${(p) => p.stateDefaultNotificationnWidth};
  flex-shrink: ${(p) => p.stateDefaultNotificationnFlexShrink};
  box-sizing: ${(p) => p.stateDefaultNotificationnBoxSizing};
  border-bottom: ${(p) => p.stateDefaultNotificationnBorderBottom};
  & img{filter: var(--filters-purple-light);}
  &.selected {
    & div {color: var(--colors-darkerblue);}
    border-bottom: 0.13020833333333331vh solid var(--colors-darkerblue);
    & img{filter: var(--filters-darkerblue);}
  }
  &:not(.selected):hover {
    & div {color: var(--colors-darkerblue);}
    border-bottom: 0.13020833333333331vh solid var(--colors-darkerblue);
    & img{filter: var(--filters-darkerblue);}
  }
`;

const StateDefaultNotificationno: FunctionComponent<
  StateDefaultNotificationnoType
> = ({
  smallFlashCards,
  text = "Flash Cards ",
  stateDefaultNotificationnCursor,
  stateDefaultNotificationnBorderW,
  stateDefaultNotificationnBorderC,
  stateDefaultNotificationnBorderS,
  stateDefaultNotificationnBackgroundColor,
  stateDefaultNotificationnWidth,
  stateDefaultNotificationnFlexShrink,
  stateDefaultNotificationnBoxSizing,
  stateDefaultNotificationnBorderBottom,
  smallFlashCardsIconOverflow,
  smallFlashCardsIconFlexShrink,
  smallFlashCardsIconOpacity,
  flashCardsDisplay,
  flashCardsColor,
  HasClass,
  onTabPrimaryB3Click,
}) => {
  return (
    <StatedefaultNotificationnoRoot
      stateDefaultNotificationnCursor={stateDefaultNotificationnCursor}
      stateDefaultNotificationnBorderW={stateDefaultNotificationnBorderW}
      stateDefaultNotificationnBorderC={stateDefaultNotificationnBorderC}
      stateDefaultNotificationnBorderS={stateDefaultNotificationnBorderS}
      stateDefaultNotificationnBackgroundColor={
        stateDefaultNotificationnBackgroundColor
      }
      stateDefaultNotificationnWidth={stateDefaultNotificationnWidth}
      stateDefaultNotificationnFlexShrink={stateDefaultNotificationnFlexShrink}
      stateDefaultNotificationnBoxSizing={stateDefaultNotificationnBoxSizing}
      stateDefaultNotificationnBorderBottom={
        stateDefaultNotificationnBorderBottom
      }
      onClick={onTabPrimaryB3Click}
      className={HasClass}
    >
      <SmallflashcardsIcon
        alt=""
        src={smallFlashCards}
        smallFlashCardsIconOverflow={smallFlashCardsIconOverflow}
        smallFlashCardsIconFlexShrink={smallFlashCardsIconFlexShrink}
        smallFlashCardsIconOpacity={smallFlashCardsIconOpacity}
      />
      <FlashCards
        flashCardsDisplay={flashCardsDisplay}
        flashCardsColor={flashCardsColor}
      >
        {text}
      </FlashCards>
    </StatedefaultNotificationnoRoot>
  );
};

export default StateDefaultNotificationno;
