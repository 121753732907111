import { FunctionComponent, useState } from "react";
import styles from "./FrameComponent.module.css";
import Property1Default6 from "./Property1Default6"

interface CustomSliderProps {
  min: number;
  max: number;
  value: number;
  darkMode: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const CustomSlider: React.FC<CustomSliderProps> = ({ min, max, value, handleChange,darkMode }) => {
  // Calculate the percentage for the slider's progress
  const percentage = ((value - min) / (max - min)) * 100;

  return (
    <div className={styles.sliderArea}>
      <input
        type="range"
        min={min}
        max={max}
        value={value}
        onChange={handleChange}
        className={(darkMode===true)?styles.slider+" "+styles.dark:styles.slider} // You need to define this class in your CSS
        style={{ background: `linear-gradient(to right, var(--colors-green) ${percentage}%, var(--colors-grey-stroke) ${percentage}%)`, zIndex:"1" }}
      />
    </div>
  );
};

const FrameComponent: FunctionComponent<{DC: boolean, darkMode: boolean}> = ({DC, darkMode}) => {
  const [value, setValue] = useState(50);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(Number(event.target.value));
  };
  return (
    <section className={styles.fRAMEParent}>
      <div className={styles.inputAmoutArea}>
        <div className={styles.customLineTextFieldSmall}>
          <div className={styles.amount}>Amount</div>
          <div className={styles.customLine}>
            <div className={styles.textfieldsmall1}>
              <input className={styles.text} type="text" value={value} onChange={handleChange} />
            </div>
            <Property1Default6
              property1DefaultWidth="6.944444444444445vw"
              property1DefaultJustifyContent="space-between"
              property1DefaultPadding="var(--padding-9xsH) var(--padding-xsW)"
              property1DefaultGap="unset"
              property1DefaultPosition="relative"
              property1DefaultTop="unset"
              property1DefaultLeft="unset"
              property1DefaultBackgroundColor="transparent"
              property1DefaultFontFamily="Onest"
              property1DefaultFontWeight="500"
              property1DefaultFontSize="1.8229166666666667vh"
              property1DefaultColor="var(--colors-purple-light)"
              property1DefaultAlignSelf="unset"
              options={["USD","EUR"]}
              value="USD"
            />
          </div>
        </div>
      </div>
      <div className={styles.radioButtonPaymentMethodArParent}>
        <div className={styles.radioButtonPaymentMethodAr}>
          <div className={styles.lineActiveFrame}>1</div>
          <div className={styles.lineActiveFrame1}>5000</div>
        </div>
        <CustomSlider darkMode={darkMode===true} min={0} max={5000} value={value} handleChange={handleChange}/>
      </div>
      {
        DC?
        <div className={styles.inputAmoutArea1}>
          <div style={{fontSize:"var(--caption-cap-12-size)", lineHeight:"2.604166666666667vh", color:"var(--colors-dark-1)"}}>
            Discount Code
          </div>
          <div className={styles.textfieldsmall1}>
            <input
              className={styles.discountCode}
              type="text"
              style={{width:"100%", padding:"0"}}
            />
          </div>
        </div>
        :
        <></>
      }
    </section>
  );
};

export default FrameComponent;
