import { FunctionComponent } from "react";
import styled from "styled-components";

const GuestCountdownTimer = styled.div`
  position: relative;
  line-height: 2.604166666666667vh;
`;
const D11h13m = styled.div`
  position: relative;
  font-size: var(--buttons-btn-input-size);
  line-height: 2.604166666666667vh;
  color: var(--colors-btn-txt);
`;
const Property1defaultRoot = styled.div`
  border-radius: var(--corner-radius-xl);
  background-color: var(--colors-dark-2);
  width: 13.333333333333334vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xlH) var(--padding-5xsW);
  box-sizing: border-box;
  gap: var(--gap-9xs);
  text-align: center;
  font-size: var(--caption-cap-12-size);
  color: var(--colors-purple-light);
  font-family: var(--buttons-btn-input);
`;

const Property1Default17: FunctionComponent = () => {
  return (
    <Property1defaultRoot>
      <GuestCountdownTimer>Guest countdown timer</GuestCountdownTimer>
      <D11h13m>{`2d 11h 13m 27s `}</D11h13m>
    </Property1defaultRoot>
  );
};

export default Property1Default17;
