import { FunctionComponent, type CSSProperties, forwardRef } from "react";
import { Property } from "csstype";
import styled from "styled-components";

type ScrollHolderType = {
  /** Style props */
  scrollHolderHeight?: CSSProperties["height"];
  scrollHeight?: CSSProperties["height"];
  scrollHolderAlignItems?: CSSProperties["alignItems"];
  scrollHolderPosition?: CSSProperties["position"];
  scrollHolderTop?: CSSProperties["top"];
  scrollIndicatorTop?: CSSProperties["top"];
  scrollHolderLeft?: CSSProperties["left"];
  scrollHolderMargin?: CSSProperties["margin"];
  scrollHolderRight?: CSSProperties["right"];
  scrollHolderFlexShrink?: CSSProperties["flexShrink"];
  scrollHolderZIndex?: CSSProperties["zIndex"];
  scrollHolderBackgroundColor?: CSSProperties["backgroundColor"];
  scrollBackgroundColor?: CSSProperties["backgroundColor"];
  hidden?: boolean;
};

const Scroll1 = styled.div<{
  scrollBackgroundColor?: Property.BackgroundColor;
  scrollHeight?: Property.Height;
  scrollTop?: Property.Top;
}>`
  position: relative;
  top: ${(p) => p.scrollTop};
  border-radius: var(--corner-radius-round);
  background-color: var(--colors-purple-light);
  width: 0.1388888888888889vw;
  height: ${(p) => p.scrollHeight};
  overflow: hidden;
  flex-shrink: 0;
  background-color: ${(p) => p.scrollBackgroundColor};
`;
const ScrollholderRoot = styled.div<{
  scrollHolderHeight?: Property.Height;
  scrollHolderAlignItems?: Property.AlignItems;
  scrollHolderPosition?: Property.Position;
  scrollHolderTop?: Property.Top;
  scrollHolderLeft?: Property.Left;
  scrollHolderMargin?: Property.Margin;
  scrollHolderRight?: Property.Right;
  scrollHolderFlexShrink?: Property.FlexShrink;
  scrollHolderZIndex?: Property.ZIndex;
  scrollHolderBackgroundColor?: Property.BackgroundColor;
}>`border-radius: var(--corner-radius-round);
  background-color: var(--colors-grey-stroke);
  height: 33.33333333333333vh;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  height: ${(p) => p.scrollHolderHeight};
  align-items: ${(p) => p.scrollHolderAlignItems};
  position: ${(p) => p.scrollHolderPosition};
  top: ${(p) => p.scrollHolderTop};
  left: ${(p) => p.scrollHolderLeft};
  margin: ${(p) => p.scrollHolderMargin};
  right: ${(p) => p.scrollHolderRight};
  flex-shrink: ${(p) => p.scrollHolderFlexShrink};
  z-index: ${(p) => p.scrollHolderZIndex};
  background-color: ${(p) => p.scrollHolderBackgroundColor};
`;

const ScrollHolder = forwardRef<HTMLDivElement, ScrollHolderType>(({
  scrollHolderHeight,
  scrollHeight,
  scrollHolderAlignItems,
  scrollHolderPosition,
  scrollHolderTop,
  scrollIndicatorTop,
  scrollHolderLeft,
  scrollHolderMargin,
  scrollHolderRight,
  scrollHolderFlexShrink,
  scrollHolderZIndex,
  scrollHolderBackgroundColor,
  scrollBackgroundColor,
  hidden=false,
}, ref) => {
  const hide=scrollHeight==="NaN%" || (/^(-?\d+(\.\d+)?%)$/.test(scrollHeight as string) && (parseFloat(scrollHeight as string) <= 0 || parseFloat(scrollHeight as string) >= 99.5));
  return (
    <ScrollholderRoot
      ref={ref}
      scrollHolderHeight={scrollHolderHeight}
      scrollHolderAlignItems={scrollHolderAlignItems}
      scrollHolderPosition={scrollHolderPosition}
      scrollHolderTop={scrollHolderTop}
      scrollHolderLeft={scrollHolderLeft}
      scrollHolderMargin={scrollHolderMargin}
      scrollHolderRight={scrollHolderRight}
      scrollHolderFlexShrink={scrollHolderFlexShrink}
      scrollHolderZIndex={scrollHolderZIndex}
      scrollHolderBackgroundColor={scrollHolderBackgroundColor}
      style={{display:(hidden || hide)?"none":""}}
    >
      <Scroll1 scrollBackgroundColor={scrollBackgroundColor} scrollHeight={scrollHeight} scrollTop={scrollIndicatorTop} />
    </ScrollholderRoot>
  );
});

export default ScrollHolder;
